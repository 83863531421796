body,
html {
  margin: 0;
  background-image: url(../public/images/background7.jpg);
  background-attachment: fixed;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}