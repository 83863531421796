/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500&family=Inter:wght@100;200;300;400;600;700&family=Montserrat:wght@100;200;300;400&family=Oooh+Baby&display=swap');

/* Typography */
h1 {
    font-family: 'Oooh Baby', cursive;
    font-size: 1.8em;
    color: rgb(32, 32, 32);
    font-weight: 400;
    letter-spacing: 0.08rem;
    line-height: 0.6em;
}

h2 {
    font-family: 'Inter';
    font-size: 1.4em;
    color: rgb(32, 32, 32);
    margin-top: 30px;
    margin-bottom: 10px;
    letter-spacing: 0.08rem;
    text-align: left;
    font-variant: small-caps;
    font-weight: 200;
}

h3 {
    font-weight: 400;
    font-family: 'Inter';
    font-size: 1.2em;
    color: rgb(32, 32, 32);
    margin-bottom: 10px;
    letter-spacing: 0.08rem;
    text-align: left;
    font-variant: small-caps;
}

h4 {
    font-weight: 300;
    font-family: 'Inter';
    font-size: 1em;
    color: rgb(32, 32, 32);
    margin-bottom: 10px;
    letter-spacing: 0.08rem;
    text-align: left;
    font-variant: small-caps;
}

p {
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01rem;
    font-size: 0.9em;
    color: rgb(61, 61, 61);
    line-height: 1.6em;
}

a {
    text-decoration: none;
    color: rgb(109, 109, 109);
    font-family: 'Inter', sans-serif;
}

a:hover {
    color: rgb(39, 39, 39);
}

/* Lists */
ul,
li {
    padding: 0;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01rem;
    font-size: 0.9em;
    color: rgb(61, 61, 61);
    line-height: 1.7em;
}

.ulFunctionalitiesAndProcess {
    list-style-type: none;
}

.listWithDots {
    list-style-type: disc;
}

.listWithDots li {
    margin-left: 20px;
}

/* Buttons */
button {
    cursor: pointer;
}

/* General Wrapper */
.wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1400px;
    position: relative;
    margin: auto;
    border-radius: 30px;
    align-items: center;
    padding-bottom: 50px;
}

/* Text Intro */
.wrapperTextIntro {
    width: 90%;
}

/* Paragraph Wrappers */
.wrapperParagraph1 {
    display: flex;
    flex-direction: row;
    margin-left: 120px;
    margin-top: 60px;
}

.wrapperParagraph1 h1 {
    margin-top: 32px;
}

.wrapperParagraph1 img {
    margin-right: 40px;
    width: 150px;
    height: auto;
}

.wrapperParagraph2 {
    display: flex;
    flex-direction: row;
    margin-right: 80px;
    margin-left: 80px;
    justify-content: center;
}

.wrapperParagraph2>div {
    width: 50%;
    box-sizing: border-box;
    padding: 0 10px;
}

.wrapperProjects {
    border: 1px solid #d6d6d6;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.95;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Creates two equal-width columns */
    gap: 20px;
    /* Adjust the gap between columns as needed */
    width: 95%;
    justify-content: center;
    /* Centers the grid within the wrapper */
}

.row {
    flex-direction: row;
    justify-content: center;
}

/* Project Card */
.projectCard {
    margin: 40px;
    margin-bottom: 60px;
}

.projectCard img {
    width: 100%;
    height: auto;
    border: 0.2px solid #ccc;
}

.textProjectCard {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.textProjectCard p {
    display: flex;
    flex-direction: row;
    justify-self: start;
}

.textProjectCard a {
    border: 0.2px solid #abaaaa;
    border-radius: 10%;
    padding: 5px;
    justify-self: end;

}

.textProjectCard a:hover {
    border-radius: 10%;
    padding: 5px;
}

/* Skills Wrapper */
.wrapperSkills {
    border: 1px solid #d6d6d6;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.95;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 1200px;
    padding: 20px;
    max-width: 1200px;
    padding-bottom: 60px;
}

.wrapperSkills h3 {
    margin-left: 40px;
    margin-bottom: 50px;
}

.wrapperSkills img {
    width: 100px;
    height: auto;
}

.skillsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    margin-left: -20px;
}

.skillItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.skillImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
}

/* Footer */
.footer p {
    color: rgb(129, 129, 129);
    margin-top: 50px;
}

/* Project Page */
.headerProjectPage {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    padding-left: 30px;
    margin-top: 10px;
}

.ProjectPageTopSection {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    width:86%;
}

.ProjectPageWrapperTopSection {
    border: 1px solid #d6d6d6;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.95;
    border-radius: 20px;
    margin-top: 2px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    width: 80%;
    justify-content: left;
    flex-direction: column;
    padding: 20px 40px 50px 40px;
    height: fit-content;
}

.ProjectPageWrapperTopSectionImg {
    display: flex;
    justify-self: center;
    align-self: center;
}

.ProjectPageTopSection img {
    width: 400px;
    height: auto;
    margin-top: 40px;
    display: flex;
    justify-self: center;
    align-self: center;
}

.ProjectPageWrapperBottomSection {
    border: 1px solid #d6d6d6;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.95;
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    width: 80%;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        width: 1000px;
        margin: auto;
    }

    .wrapperTextIntro {
        width: 950px;
        padding: 10px;
    }

    .wrapperParagraph2 {
        display: flex;
        flex-direction: column;
    }

    .wrapperParagraph2>div {
        width: 100%;
    }

    .wrapperParagraph2 p {
        font-size: 1.8em;
    }

    .footer p {
        font-size: 1.8em;

    }

    .wrapperProjects {

        display: flex;
        width: 90%;
        flex-direction: column;
        align-items: center;
    }

    .row {
        flex-direction: row;
        justify-content: center;

    }

    .projectCard {
        margin: 40px;
        margin-bottom: 60px;
    }

    .projectCard img {
        width: 600px;
        height: auto;
        border: 0.2px solid #ccc;
    }

    .wrapperSkills {
        width: 90%;
    }

    .skillsContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    .ProjectPageTopSection {
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .ProjectPageWrapperTopSection {
        width: 80%;
    }

    .ProjectPageWrapperBottomSection {
        width: 70%;
    }

}
